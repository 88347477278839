import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { GatsbySSR } from 'gatsby';
import i18n from './src/components/common/i18n';

const headComponents = [
  <link
    key="global-style"
    type="text/css"
    href="https://assets.jointacademy.com/framework/v1.4.0/style.css?v=qwpojd"
    rel="stylesheet"
  />,
  <link
    key="sms"
    type="text/css"
    href="https://assets.jointacademy.com/framework/sms/v4.0.0/sms.css"
    rel="stylesheet"
  />,
];

export const onRenderBody: GatsbySSR['onRenderBody'] = ({ setHeadComponents, setPostBodyComponents }) => {
  setHeadComponents(headComponents);
  setPostBodyComponents([
    <script src="https://assets.jointacademy.com/framework/v1.4.0/script.js" defer key="framework" />,
    <script src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js" defer key="intl" />,
    <script src="https://apps.elfsight.com/p/platform.js" defer key="elfsight" />,
  ]);
};

export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => (
  <I18nextProvider i18n={i18n}>
    {element}
  </I18nextProvider>
);
