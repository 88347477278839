exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-fr-fr-cou-index-tsx": () => import("./../../../src/pages/fr/fr/cou/index.tsx" /* webpackChunkName: "component---src-pages-fr-fr-cou-index-tsx" */),
  "component---src-pages-fr-fr-epaule-index-tsx": () => import("./../../../src/pages/fr/fr/epaule/index.tsx" /* webpackChunkName: "component---src-pages-fr-fr-epaule-index-tsx" */),
  "component---src-pages-fr-fr-genou-index-tsx": () => import("./../../../src/pages/fr/fr/genou/index.tsx" /* webpackChunkName: "component---src-pages-fr-fr-genou-index-tsx" */),
  "component---src-pages-fr-fr-hanche-index-tsx": () => import("./../../../src/pages/fr/fr/hanche/index.tsx" /* webpackChunkName: "component---src-pages-fr-fr-hanche-index-tsx" */),
  "component---src-pages-fr-fr-index-tsx": () => import("./../../../src/pages/fr/fr/index.tsx" /* webpackChunkName: "component---src-pages-fr-fr-index-tsx" */),
  "component---src-pages-fr-fr-mal-de-dos-index-tsx": () => import("./../../../src/pages/fr/fr/mal-de-dos/index.tsx" /* webpackChunkName: "component---src-pages-fr-fr-mal-de-dos-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-se-sv-annat-index-tsx": () => import("./../../../src/pages/se/sv/annat/index.tsx" /* webpackChunkName: "component---src-pages-se-sv-annat-index-tsx" */),
  "component---src-pages-se-sv-axel-index-tsx": () => import("./../../../src/pages/se/sv/axel/index.tsx" /* webpackChunkName: "component---src-pages-se-sv-axel-index-tsx" */),
  "component---src-pages-se-sv-fallprevention-index-tsx": () => import("./../../../src/pages/se/sv/fallprevention/index.tsx" /* webpackChunkName: "component---src-pages-se-sv-fallprevention-index-tsx" */),
  "component---src-pages-se-sv-hoft-index-tsx": () => import("./../../../src/pages/se/sv/hoft/index.tsx" /* webpackChunkName: "component---src-pages-se-sv-hoft-index-tsx" */),
  "component---src-pages-se-sv-index-tsx": () => import("./../../../src/pages/se/sv/index.tsx" /* webpackChunkName: "component---src-pages-se-sv-index-tsx" */),
  "component---src-pages-se-sv-kna-index-tsx": () => import("./../../../src/pages/se/sv/kna/index.tsx" /* webpackChunkName: "component---src-pages-se-sv-kna-index-tsx" */),
  "component---src-pages-se-sv-nacke-index-tsx": () => import("./../../../src/pages/se/sv/nacke/index.tsx" /* webpackChunkName: "component---src-pages-se-sv-nacke-index-tsx" */),
  "component---src-pages-se-sv-osteoporos-index-tsx": () => import("./../../../src/pages/se/sv/osteoporos/index.tsx" /* webpackChunkName: "component---src-pages-se-sv-osteoporos-index-tsx" */),
  "component---src-pages-se-sv-rygg-index-tsx": () => import("./../../../src/pages/se/sv/rygg/index.tsx" /* webpackChunkName: "component---src-pages-se-sv-rygg-index-tsx" */),
  "component---src-pages-us-en-back-index-tsx": () => import("./../../../src/pages/us/en/back/index.tsx" /* webpackChunkName: "component---src-pages-us-en-back-index-tsx" */),
  "component---src-pages-us-en-balance-index-tsx": () => import("./../../../src/pages/us/en/balance/index.tsx" /* webpackChunkName: "component---src-pages-us-en-balance-index-tsx" */),
  "component---src-pages-us-en-hip-index-tsx": () => import("./../../../src/pages/us/en/hip/index.tsx" /* webpackChunkName: "component---src-pages-us-en-hip-index-tsx" */),
  "component---src-pages-us-en-index-tsx": () => import("./../../../src/pages/us/en/index.tsx" /* webpackChunkName: "component---src-pages-us-en-index-tsx" */),
  "component---src-pages-us-en-knee-index-tsx": () => import("./../../../src/pages/us/en/knee/index.tsx" /* webpackChunkName: "component---src-pages-us-en-knee-index-tsx" */),
  "component---src-pages-us-en-neck-index-tsx": () => import("./../../../src/pages/us/en/neck/index.tsx" /* webpackChunkName: "component---src-pages-us-en-neck-index-tsx" */),
  "component---src-pages-us-en-osteoporosis-index-tsx": () => import("./../../../src/pages/us/en/osteoporosis/index.tsx" /* webpackChunkName: "component---src-pages-us-en-osteoporosis-index-tsx" */),
  "component---src-pages-us-en-shoulder-index-tsx": () => import("./../../../src/pages/us/en/shoulder/index.tsx" /* webpackChunkName: "component---src-pages-us-en-shoulder-index-tsx" */)
}

